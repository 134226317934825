import React,{Fragment,useEffect,useState } from "react";
import Search from "../debris/search/Search";
export default function Page404(){
    const [param, setParam] = useState({});
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }

    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'404'} FirstPage={'主页'} FirstPageUrl={'/home'} CurrentPage={'404'}></Search>
            <div id="page_container">
                <img src="/images/404.png" alt="" />
            </div>
        </Fragment>
    )
}