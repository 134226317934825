const Dropdown = ({ submenus, dropdown  }) => {
    return (
        <ul className={`dropdown ${dropdown ? "show" : ""}`}>
            {submenus.map((submenu, index) => (
                <li key={index} className="menu-items">
                    <a href={submenu.menuUrl}>{submenu.menuName}</a>
                </li>
            ))}
        </ul>
    );
};

export default Dropdown;
