function assemblyParam(path,params,property){
    path = path || ''
    params = params || {}
    params = {...params, ...(property || {})}
    if (path === '#') return path
    const keys = Object.keys(params)
    for(const index in keys) {
        const reg = new RegExp("\\{" + keys[index] + "\\}", "g")
        var myArray = reg.exec(path);
        path = path.replace(reg, params[keys[index]]);
        if(myArray) delete params[keys[index]]
    }
    const queryString = new URLSearchParams(Object.entries(params)).toString()
    let index = path.indexOf("?");
    if(index>0){
        if(path.endsWith("?")){
            path = path.concat(queryString)
        }else {
            if(path.endsWith("&")){
                path = path.concat(queryString)
            } else {
                path = path.concat(queryString?'&'.concat(queryString):'')
            }
        }
    }else {
        path = path.concat(queryString?'?'.concat(queryString):'')
    }
    return path;
}

export {
    assemblyParam
}