import React,{Fragment,useEffect,useState } from "react";
import { useParams } from 'react-router-dom'
import Search from "../debris/search/Search";
import Content from "../debris/content/Content";
import Attachment from "../debris/attachment/Attachment";
import {getSystem, noticeContent} from "../../api/articlePage";

export default function NoticeContent(){
    const params = useParams();
    const [content, setContent] = useState({});
    const [param, setParam] = useState({id: params.id, systemCode: getSystem()});
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    useEffect(() => {
        noticeContent(param).then(response => {
            const data = response.result || {}
            setContent({id: data.id,title: data.noticeSubtitle,description: data.noticeAbstract,content: data.noticeContent,author: data.noticeAuthor,
                releaseTime: data.noticeStartTime ,offshelfTime: data.noticeEndTime})
        })
    }, [param])
    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'公告列表'} FirstPageUrl={'/notice/default'} CurrentPage={'公告详情'}></Search>
            <div className="page_container">
                <div className="container">
                    <Content Paragraph={ content }></Content>
                </div>
                <aside>
                    <Attachment Module={'noticeContent'} Params={param} ></Attachment>
                </aside>
            </div>
        </Fragment>
    )
}