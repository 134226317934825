import React,{Fragment,useEffect,useState } from "react";
import { Empty } from 'react-vant';
import { Icon, Badge  } from 'react-vant'
import { Like,GoodJob,Label } from '@react-vant/icons';
import './Content.css'
export default function Content({Paragraph}){
    const [content, setContent] = useState({});
    const [date, setDate] = useState({year: '',month: '',day: ''});
    const [paragraph, setParagraph] = useState({id:'',title:'标题',description:'描述',content:'内容',multimedia: '',inside: true,
        author:'作者',releaseTime:'发布时间',offshelfTime:'下架时间'});
    function click(event) {
        console.log(event)
    }
    useEffect(() => {
        setParagraph(Paragraph)
        const releaseTimeStr = Paragraph.releaseTime
        const releaseTime = new Date(releaseTimeStr);
        setDate({year: releaseTime.getFullYear(),month: releaseTime.getMonth(),day: releaseTime.getDate()})
    }, [Paragraph])
    return (
        <article>
            <div className="categ">
                <div className="meta"><strong>{date.day}</strong>
                    <small>{date.month} 月<br></br>{date.year}</small>
                </div>
                <ul>
                    {/*<li>类型: <a href="#">Responsive</a></li>*/}
                    {/*<li><a href="single.html">3 评论</a></li>*/}
                    <li>作者: <a href="single.html">{paragraph.author}</a></li>
                    {paragraph.offshelfTime &&  <li>截止: {paragraph.offshelfTime}</li> }
                </ul>
            </div>
            <div className="postblog">
                {(paragraph.multimedia && paragraph.multimedia !== '#') && (
                    paragraph.inside? <a href="#" className="image-blog"><img src={paragraph.multimedia} alt=""/></a>:
                        <div className="video-container"><iframe src={paragraph.multimedia} width="800" height="450" frameBorder="0"></iframe></div>
                )}
                <h3><a href="#">{paragraph.title}</a></h3>
                {paragraph.description &&  <p><div className="article-description" dangerouslySetInnerHTML={{__html: paragraph.description}}></div></p> }
                <p> <div className="article-paragraph" dangerouslySetInnerHTML={{__html: paragraph.content}}></div></p>
                <ul className="addthis_toolbox addthis_default_style addthis_32x32_style">
                    <li><a className="addthis_button_preferred_1"></a></li>
                    <li><a className="addthis_button_preferred_2"></a></li>
                    <li><a className="addthis_button_preferred_3"></a></li>
                    <li><a className="addthis_button_preferred_4"></a></li>
                    <li><a className="addthis_button_compact"></a></li>
                    <li><a className="addthis_counter addthis_bubble_style"></a></li>
                </ul>
            </div>
            {/*<div className="about-the-author">*/}
                {/*<h3>作者简介</h3>*/}
                {/*<img src="../../../../public/images/author.jpg" alt="" title="author"/>*/}
                {/*<h4>Louie Jie Mahusay</h4>*/}
                {/*<p>Hi my name is louiejiemahusay lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae mi*/}
                    {/*sed felis suscipit dictum. Nam malesuada elit id metus euismod auctor. Vivamus placerat auctor*/}
                    {/*libero eu sollicitudin. Cras et tortor vitae metus tempor dapibus aliquam sit amet quam.</p>*/}
            {/*</div>*/}
            {/*<ul className="post_comment">*/}
                {/*<li className="page_title"><h3>3 条评论</h3></li>*/}
                {/*<li>*/}
                    {/*<img src="images/user.jpg" alt=""/>*/}
                    {/*<div>*/}
                        {/*<span><strong>Louie Jie </strong>Says:</span>*/}
                        {/*<small>May 30 | 10:23 pm</small>*/}
                        {/*<p>Hello! thanks for the blog.</p>*/}
                        {/*<span><a href="#" className="link">Comment &rArr;</a></span>*/}
                    {/*</div>*/}
                {/*</li>*/}
                {/*<li className="reply">*/}
                    {/*<img src="images/user.jpg" alt=""/>*/}
                    {/*<div>*/}
                        {/*<span><strong>John Doe </strong>Says:</span>*/}
                        {/*<small>May 29 | 09:00 pm</small>*/}
                        {/*<p>Nice site! I will visit everyday here just to read your blog. I Inspired those articles that*/}
                            {/*you've posted. :-)</p>*/}
                        {/*<span><a href="#" className="link">Comment &rArr;</a></span>*/}
                    {/*</div>*/}
                {/*</li>*/}
                {/*<li className="reply">*/}
                    {/*<img src="images/user.jpg" alt=""/>*/}
                    {/*<div>*/}
                        {/*<span><strong>John Doe </strong>Says:</span>*/}
                        {/*<small>May 29 | 09:00 pm</small>*/}
                        {/*<p>Nice site! I will visit everyday here just to read your blog. I Inspired those articles that*/}
                            {/*you've posted. :-)</p>*/}
                        {/*<span><a href="#" className="link">Comment &rArr;</a></span>*/}
                    {/*</div>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<img src="images/user.jpg" alt=""/>*/}
                    {/*<div>*/}
                        {/*<span><strong>John Doe </strong>Says:</span>*/}
                        {/*<small>May 28 | 09:00 pm</small>*/}
                        {/*<p>Nice site! I will visit everyday here just to read your blog. I Inspired those articles that*/}
                            {/*you've posted. :-)</p>*/}
                        {/*<span><a href="#" className="link">Comment &rArr;</a></span>*/}
                    {/*</div>*/}
                {/*</li>*/}
                {/*<li>*/}
                    {/*<img src="images/user.jpg" alt=""/>*/}
                    {/*<div>*/}
                        {/*<span><strong>John Doe </strong>Says:</span>*/}
                        {/*<small>May 28 | 09:00 pm</small>*/}
                        {/*<p>Nice site! I will visit everyday here just to read your blog. I Inspired those articles that*/}
                            {/*you've posted. :-)</p>*/}
                        {/*<span><a href="#" className="link">Comment &rArr;</a></span>*/}
                    {/*</div>*/}
                {/*</li>*/}
            {/*</ul>*/}
        </article>
    )
}