import { Fragment,useState } from "react";
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import Resume from "../debris/resume/Resume"
export default function ResumePage(){
    const [param, setParam] = useState({});
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'主页'} FirstPage={'主页'} FirstPageUrl={'/home'} CurrentPage={'我的简历'}></Search>
            <div className="page_container">
                <div className="container">
                    <Resume></Resume>
                </div>
                <aside>
                    <Attachment Module={'article'} Params={param} ></Attachment>
                </aside>
            </div>
        </Fragment>
    );
}