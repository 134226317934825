import './Search.css'
export default function Search(props){
    function search(param) {
        props.searchKeyword(param);
    }
    const goBackPage = () => {
        window.history.back()
    }
    return (
        <div className="breadcrumbs">
            <div className="second">
                <a href={props.FirstPageUrl || '#'}> <h2>{props.Home}</h2></a>
                <span className="left"><a href="#" onClick={goBackPage} >上一页</a></span>
                {props.CurrentPage &&  <span>&rArr;  {props.CurrentPage}</span>}
            </div>
            {/*<form className="searchForm">*/}
                {/*<fieldset>*/}
                    {/*<div className="input">*/}
                        {/*<input type="text"  onChange={e => {search(e.target.value);}}  name="s" className="inputSearch" placeholder="Search"></input>*/}
                    {/*</div>*/}
                    {/*<input type="submit" className="searchSubmit" value=""></input>*/}
                {/*</fieldset>*/}
            {/*</form>*/}
        </div>
    )
}