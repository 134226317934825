import Dropdown from "./Dropdown";
import { useState } from "react";

const MenuItems = ({ items }) => {
    const [dropdown, setDropdown] = useState(false);
    return (
        <li className="menu-items">
            {items.subBaseMenus ? (
                <>
                    <button type="button" aria-haspopup="menu"  aria-expanded={dropdown ? "true" : "false"}
                            onClick={() => setDropdown((prev) => !prev)}>
                        {dropdown ? '⮈' :'⮋'} {items.menuName}
                    </button>
                    <Dropdown submenus={items.subBaseMenus} dropdown={dropdown}  />
                </>
            ) : (
                <a href={items.menuUrl}>{items.menuName}</a>
            )}
        </li>
    );
};

export default MenuItems;
