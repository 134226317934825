import './Post.css'

export default function Post({Theme,Recommends}){
    return (
        <div className="whole">
            {Theme && <h3>{Theme}</h3>}
            { (Recommends && Recommends.length > 0 ) &&
                <ul className="post">
                    {
                        Recommends.map((recommend,index) => (
                            <li key={index}>
                                <a href={recommend.recommendUrl}>
                                    { (recommend.recommendCover && recommend.recommendCover !== '#') && <img src={recommend.recommendCover} alt={recommend.recommendAuthor} />}
                                </a>
                                <p><a href={recommend.recommendUrl}>{recommend.recommendName}</a> <small>{recommend.recommendOnlineTime}</small></p>
                            </li>
                        ))
                    }
                </ul>
            }
        </div>
    )
}