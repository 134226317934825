import React,{Fragment,useEffect,useState } from "react";
import {getSystem, noticePage, timeLineContent} from "../../api/articlePage";
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import Timewarp from "../debris/timewarp/Timewarp"
import Pagination from "../debris/pagination/Pagination"
import {noticeTop} from "../../api/welcomePage";

export default function Blog(){
    const [param, setParam] = useState({pageSize: 10,pageNo: 1,systemCode: getSystem()});
    const [pages, setPages] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);

    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }

    function clickPage(pageNo) {
        setPageNo(pageNo)
        setParam({ ...param, ...{pageNo: pageNo}})
    }

    useEffect(() => {
        timeLineContent(param).then(response => {
            const result = response.result
            setPages( result ? result.rows : [])
            setPageTotal(result ? (result.total || 0) : 0)
        })
    },[param])

    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'博客'} FirstPageUrl={'/blog'} CurrentPage={'时间线'}></Search>
            <div className="page_container">
                <div className="container">
                    {pages.map((item,index) => (
                        <Timewarp TimeLine={item}></Timewarp>
                    ))}
                    <Pagination clickPage={clickPage.bind(this)} Total={pageTotal} PageNo={pageNo} PageSize={param.pageSize} Span={2}></Pagination>
                </div>
                <aside>
                    <Attachment Module={'blog'} Params={param} ></Attachment>
                </aside>
            </div>
        </Fragment>
    )
}