import { Typography, Icon, Badge  } from 'react-vant'
import { Like,GoodJob,Label } from '@react-vant/icons';
import './Brief.css'
import {assemblyParam} from '../../../utils/universal'
import React,{Fragment,useEffect,useState } from "react";
export default function Brief({ArticleContent,Href}){
    
    function click(event) {
        console.log(event)
    }
    const [href, setHref] = useState('#');
    const [content, setContent] = useState({id:'主键',title:'标题',signature:'签名',abstract:'摘要',cover:'封面'});
    const [decoration, setDecoration] = useState({like: 0, assistant: 0, collection: 0});
    useEffect(() => {
        setContent(ArticleContent || {})
        setHref(Href)
        setDecoration(null)
    }, [ArticleContent,Href])
    
    return (
       <Fragment>
           {content && (
               <div className="article-item">
                   {content.cover && <a  href={assemblyParam(href,{id:content.id},{})}><img alt={content.signature} src={content.cover} /></a> }
                   <div className="article-main">
                       <div className="article-title">
                           <a  href={assemblyParam(href,{id:content.id},{})}>{content.title}</a>
                           {content.signature && <p>{content.signature}</p> }
                       </div>
                       <Typography.Text ellipsis={{rows: 2, collapseText: '收起', expandText: '展开',}}>{content.abstract}</Typography.Text>
                       {decoration && (
                           <div className="article-foot">
                               <Badge content={decoration.like ? (decoration.like > 99 ? '99+' : decoration.like ) : '0'}>
                                   <Like onClick={click}/>
                               </Badge>
                               <Badge content={decoration.assistant ? (decoration.assistant > 99 ? '99+' : decoration.assistant ) : '0'}>
                                   <GoodJob onClick={click} />
                               </Badge>
                               <Badge content={decoration.collection ? (decoration.collection > 99 ? '99+' : decoration.collection ) : '0'}>
                                   <Label onClick={click} />
                               </Badge>
                           </div>
                       )}
                   </div>
               </div>
           )}
       </Fragment>
    )
}