import { useParams } from 'react-router-dom'
import { useEffect,useState } from "react";
import {getCategoryItems} from "../../api/articlePage";
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import Item from "../debris/item/Item";
import { Empty } from 'react-vant';

export default function Catalogue(props){
    const params = useParams();
    const [param, setParam] = useState({});
    const [items, setItems] = useState([]);

    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    useEffect(() => {
        getCategoryItems('default',params.id || 0).then(response => {
            setItems(
                (response.result || []).map(item => {return {
                    id: item.id ,
                    name: item.categoryName,
                    url: item.categoryUrl,
                    property: JSON.parse(item.categoryProperty || '{}'),
                    list: (item.subsets || []).map(subitem => { return {
                        id: subitem.id ,
                        url: subitem.categoryUrl,
                        name: subitem.categoryName,
                        property: JSON.parse(subitem.categoryProperty || '{}'),
                        hashNext: (subitem.subsets && subitem.subsets.length > 0) ? true : false
                    }
                    })
                }})
            )
        })
    }, [param])

    return (
        <>
            <Search searchKeyword={searchParam.bind(this)} Home={'博客'} FirstPage={'服务应用'} FirstPageUrl={'/blog'} CurrentPage={'品类列表'}></Search>
            <div className="page_container">
                <div className="container">
                    {(items && items.length >0 )? (
                        <Item Items={items} Basepath={'about'}></Item>
                    ) : <Empty description="目录为空" />}
                </div>
                <aside>
                    <Attachment Module={'catalpgue'} Params={param} ></Attachment>
                </aside>
            </div>
        </>
    )
}