import './Recommend.css'
export default function Recommend({theme,items}){
    return (
        <div className="row">
            {theme && (<div className="page-title"><h3>{theme}</h3></div>)}
            {
                items.map((item,index) => (
                    <div className={ index % 4 === 0 ? "one-fourth serv first" : (index % 4 === 2 ? "one-fourth serv second" : "one-fourth serv" ) } key={index}>
                        <img src={item.icon} alt={item.alt}/>
                        <h3>{item.theme}</h3>
                        <div  dangerouslySetInnerHTML = {{__html:item.content}}></div>
                    </div>
                ))
            }
        </div>
    )
}