import { Swiper, Image } from 'react-vant';
import { Fragment,useEffect,useState } from "react";
import {carouselPreview,noticeTop,recommendTop} from "../../api/welcomePage";
import Recommend from "../debris/recommend/Recommend";
import TabsShow from "../debris/tabs/TabsShow";
import Product from "../debris/product/Product";
import Column from "../debris/column/Column";
import './home.css'
export default function Home({Signature,MenuItems}){
    const [onload, setOnload] = useState(true);
    const [preview, setPreview] = useState([]);
    const [notices, setNotice] = useState([]);
    const [recommends, setRecommends] = useState([]);
    const [service, setService] = useState([]);

    const changeActiveComponent =(name) => {
        return new Promise((resolve, reject) => {
            recommendTop(name,10,'').then(response => {
                resolve((response.result || []).map(recommend => {return { title: recommend.recommendName , url: recommend.recommendUrl ,author: recommend.recommendAuthor
                    , pic: recommend.recommendCover , content: recommend.recommendDescription, attribute: JSON.parse(recommend.recommendProperty || '{}') }}))
            })
        })
    }

    useEffect(() => {
        if(onload) {
            carouselPreview().then(response => {
                setPreview([...response.result])
            })
            noticeTop('default',3).then(response => {
                setNotice([...response.result])
            })
            recommendTop('advertisement',4,'').then(response => {
                const result = response.result || []
                if ( result ) {
                    setRecommends(result.map(recommend => {
                        const recommendProperty = JSON.parse(recommend.recommendProperty || '{}')
                        return {...recommendProperty,...{thumbLink: recommend.recommendUrl ,tag: recommend.recommendTags, num: '1',
                                title: recommend.recommendName, thumb: recommend.recommendCover  }, ...(recommend.recommendDescription?{desc: recommend.recommendDescription }:{})}
                    }))
                }
            })
            noticeTop('service',4).then(response => {
                setService([...(response.result || []).map(item => { return { icon: item.noticeIcon , alt: item.noticeSubtitle, theme: item.noticeTitle ,content: item.noticeContent }})])
            })
            setOnload(false)
        }
    },[onload])
    return (
      <Fragment>
          <div class="intro-title"  dangerouslySetInnerHTML = {{__html:Signature}}></div>
          <div className="flexslider clearfix">
              <Swiper  autoplay={5000}>
                  {preview.map((image) => (
                      <Swiper.Item key={image.carouselUrl}>
                          <Image src={image.carouselUrl} />
                      </Swiper.Item>
                  ))}
              </Swiper>
          </div>
          <div className="page_container">
              <Column ColumnNum={notices.length === 1 ?  1: (notices.length === 2 ? 2: 3) }  Columns = {notices}></Column>
              {(recommends && recommends.length > 0) && <Product theme={'商品推荐'} products={recommends}></Product>}
              <TabsShow CheckIndex={0} Themes={[{value: 'recommend',type: 'article',label: '推荐'},{value: 'information',type: 'article',label: '资讯'},
                  {value: 'attention',type: 'article',label: '关注'},{value: 'dynamic',type: 'article',label: '动态'}]} LoadContentFunction={changeActiveComponent} ></TabsShow>
              {(service && service.length > 0) &&  <Recommend theme={'我的服务'} items={service}></Recommend>}
          </div>
      </Fragment>
    );
}
