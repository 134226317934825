import React,{Fragment,useEffect,useState } from "react";
import { useParams } from 'react-router-dom'
import { Empty } from 'react-vant';
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import Brief from "../debris/brief/Brief";
import Pagination from "../debris/pagination/Pagination"
import {getSystem,noticePage} from "../../api/articlePage";

export default function Notice(){
    const params = useParams();
    const [param, setParam] = useState({pageSize: 10,pageNo: 1,noticeSignage: params.signboard, systemCode: getSystem()});
    const [pages, setPages] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);

    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    function clickPage(pageNo) {
        setPageNo(pageNo)
        setParam({ ...param, ...{pageNo: pageNo}})
    }
    useEffect(() => {
        noticePage(param).then(response => {
            const result = response.result
            setPages( result ? result.rows : [])
            setPageTotal(result ? (result.total || 0) : 0)
        })
    }, [param])

    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'品类列表'} FirstPageUrl={'/catalogue'} CurrentPage={'公告列表'}></Search>
            <div className="page_container">
                {(pageTotal && pageTotal > 0 ) ?
                    <Fragment>
                        <div className="container">
                            {pages.map((item,index) => (
                                <Brief key={index} ArticleContent={{id: item.id,title: item.noticeTitle,signature: item.noticeAuthor + '(' + item.noticeStartTime + '--' + item.noticeEndTime +')'
                                    ,abstract: item.noticeAbstract ,cover: item.noticeIcon}} Href={'/noticeDetails/{id}'}></Brief>
                            ))}
                            <Pagination clickPage={clickPage.bind(this)} Total={pageTotal} PageNo={pageNo} PageSize={param.pageSize} Span={2}></Pagination>
                        </div>
                        <aside>
                            <Attachment Module={'noticeContent'} Params={param} ></Attachment>
                        </aside>
                    </Fragment>
                    : <Empty description="目录为空" /> }

            </div>
        </Fragment>
    )
}