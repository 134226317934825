export default function Column({ColumnNum,Columns}){
    return (
        <div className="row">
            { ColumnNum === 1 && Columns.map((notice,index) => (
                <div className='whole' key={index}>
                    { notice.noticeIcon &&  <div className="sec"> <a href={notice.noticeForwardUrl}><img src={notice.noticeIcon} alt=""/></a> </div> }
                    <h3>{notice.noticeTitle}</h3>
                    <div  dangerouslySetInnerHTML = {{__html:notice.noticeAbstract}}></div>
                </div>
            ))}
            { ColumnNum === 2 && Columns.map((notice,index) => (
                <div className={index%1===0? 'one-half first': 'one-half'} key={index}>
                    { notice.noticeIcon &&  <div className="sec"> <a href={notice.noticeForwardUrl}><img src={notice.noticeIcon} alt=""/></a> </div> }
                    <h3>{notice.noticeTitle}</h3>
                    <div  dangerouslySetInnerHTML = {{__html:notice.noticeAbstract}}></div>
                </div>
            ))}
            { ColumnNum === 3 && Columns.map((notice,index) => (
                <div className={index%2===0? 'one-third first': 'one-third'} key={index}>
                    { notice.noticeIcon &&  <div className="sec"> <a href={notice.noticeForwardUrl}><img src={notice.noticeIcon} alt=""/></a> </div> }
                    <h3>{notice.noticeTitle}</h3>
                    <div  dangerouslySetInnerHTML = {{__html:notice.noticeAbstract}}></div>
                </div>
            ))}

            { ColumnNum === 4  &&Columns.map((notice,index) => (
                <div className={index%3===0? 'one-fourth first': (index%3 ===2?'one-third': 'one-fourth second')} key={index}>
                    { notice.noticeIcon &&  <div className="sec"> <a href={notice.noticeForwardUrl}><img src={notice.noticeIcon} alt=""/></a> </div> }
                    <h3>{notice.noticeTitle}</h3>
                    <div  dangerouslySetInnerHTML = {{__html:notice.noticeAbstract}}></div>
                </div>
            ))}
        </div>
    )
}