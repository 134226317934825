/**
 * 网络请求配置
 */
import axios from "axios";
const systemCode = "1"
axios.defaults.timeout = 100000;
axios.defaults.baseURL = "http://cuixichang.com/api/";

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
    (config) => {
        config.data = JSON.stringify(config.data);
        config.headers = {
            "System-Source-Code": systemCode,
            "Content-Type": "application/json",
        };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
    (response) => {
        if (response.data && response.data.code === -1) {
            console.log("认证token已过期");
        }
        return response;
    }, (error) => {
        console.log("请求出错：", error);
        return Promise.reject(error)
    }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then((response) => {
            landing(url, params, response.data);
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                landing(url, data, response.data);
                //关闭进度条
                resolve(response.data);
            }, (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                landing(url, data, response.data);
                resolve(response.data);
            }, (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                landing(url, data, response.data);
                resolve(response.data);
            }, (err) => {
                msag(err);
                reject(err);
            }
        );
    });
}

//统一接口处理，返回数据
export default function http(fecth, url, param) {
    return new Promise((resolve, reject) => {
        switch (fecth) {
            case "get":
                console.log("begin a get request,and url:", url);
                get(url, param).then(function (response) {
                        resolve(response);
                    }).catch(function (error) {
                        console.log("get request GET failed.", error);
                        reject(error);
                    });
                break;
            case "post":
                post(url, param).then(function (response) {
                        resolve(response);
                    }).catch(function (error) {
                        console.log("get request POST failed.", error);
                        reject(error);
                    });
                break;
            default:
                break;
        }
    });
}

export function getSystem(){
    return systemCode
}

//失败提示
function msag(err) {
    if (err && err.response) {
        switch (err.response.code) {
            case 400:
                alert(err.response.message);
                break;
            case 204:
                alert("暂无数据");
                break;
            case 401:
                alert("抱歉，您没有该执行权限");
                break;
            case -1:
                alert("认证token已过期");
                break;
            case 504:
                alert("服务请求超时");
                break;
            case 405:
                alert("请求方法未实现");
                break;
            default:
        }
    }
}

/**
 * 查看返回的数据
 * @param url
 * @param params
 * @param data
 */
function landing(url, params, data) {
   // console.log('url:'+ url + ';params:' + JSON.stringify(params) + ';data:' + JSON.stringify(data))
}