import React,{Fragment,useEffect,useState } from "react";
export default function Pagination({clickPage,Total,PageNo,PageSize,Span}){
    const [pageNo, setPageNo] = useState(1);
    const [pages, setPages] = useState(0);
    const [interval, setInterval] = useState([]);

    function nodePages(total,pageSize){
       return Math.ceil(total/pageSize)
    }

    const change = (param)=>{
        return (event)=>{
            clickPage(param)
        }
    }

    function nodePageNo(pages,pageNo){
        pageNo = ( pageNo < 0 ? 1 : pageNo)
        return pages > pageNo ? pageNo : pages
    }

    function nodeInterval(pages,pageNo,span){
       let count = span * 2 + 1
       let start = []
       let end = []
       while (count > 1) {
           const nodeStart = (pageNo - start.length)
          if(nodeStart > 1){
              start.push(nodeStart - 1)
              count--
          }
           const nodeEnd = (pageNo + end.length)
          if(nodeEnd < pages){
              end.push(nodeEnd + 1)
              count--
          }
          if((nodeStart <= 1) && (nodeEnd >= pages)){
              count = 0
          }
       }
       let node = [...start,pageNo,...end]
       return node.sort( function(first,last){return first - last} )
    }

    useEffect(() => {
        const pagesNode = nodePages( Total || 0,PageSize || 10)
        setPages(pagesNode)
        const pageNoNode = nodePageNo(pagesNode,PageNo)
        setPageNo(pageNoNode)
        setInterval(nodeInterval(pagesNode,pageNoNode,Span||2))
    },[PageNo,Total,PageSize,Span])

    return (
        <Fragment>
            { (interval.length > 1) && (
                <ul className="pagination">
                    { pages > 1 && <li className="page">Page {pageNo} of {pages}</li> }
                    { interval[0] > 1 && <li onClick={change(pageNo-1)}><a href="#">&laquo;</a></li> }
                    { interval.map((num,index) => (  <li key={index} onClick={change(num)}>{(num === pageNo)? <a href="#" className="active">{num}</a> : <a href="#">{num}</a> }</li>))}
                    { pages > interval[interval.length-1] && <li onClick={change(pageNo+1)}><a href="#">&raquo;</a></li> }
                    { pages > interval[interval.length-1] && <li onClick={change(pages)}><a href="#">last</a></li> }

                </ul>
            ) }
        </Fragment>
    )
}