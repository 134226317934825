import React,{Fragment,useEffect,useState } from "react";
import { Empty } from 'react-vant';
import { useParams } from 'react-router-dom'
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import {getSystem, articleContent, albumContent} from "../../api/articlePage";
import Pagination from "../debris/pagination/Pagination"
export default function PortfolioContent(){
    const params = useParams();
    const [param, setParam] = useState({id: params.id, systemCode: getSystem(),pageSize:12});
    const [albumName, setAlbumName] = useState([]);
    const [pages, setPages] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);

    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    function clickPage(pageNo) {
        setPageNo(pageNo)
        setParam({ ...param, ...{pageNo: pageNo}})
    }
    useEffect(() => {
        albumContent(param).then(response => {
            const result = response.result
            setAlbumName(result ? result.albumSignboard : '')
            setPages( result ? result.previewUrls : [])
            setPageTotal(result ? (result.total || 0) : 0)
        },error => {
            console.log("网络异常~",error);
        })
    }, [param])
    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'相册列表'} FirstPageUrl={'/portfolio'} CurrentPage={'相册详情'}></Search>
            <div className="page_container">
                <div className="container">
                    <section className="options clearfix">
                        <ul className="filters option-set clearfix" data-option-key="filter">
                            <li><a href="#" data-option-value="*">详情</a></li>
                            <li>/<a href="#" data-option-value=".illustration">{albumName}</a></li>
                        </ul>
                    </section>
                    <div className="works-container">
                        {(pageTotal && pageTotal > 0 ) ?
                        <Fragment>
                            {pages.map((item,index) => (
                                <div className="element motion mosaic-block-sidebar views-side" data-category="print">
                                    <a rel="prettyPhoto[gallery1]" title="Image Title Here" className="mosaic-overlay"></a>
                                    <img src={item} alt="Image"/>
                                </div>
                            ))}
                            <Pagination clickPage={clickPage.bind(this)} Total={pageTotal} PageNo={pageNo} PageSize={param.pageSize} Span={2}></Pagination>
                        </Fragment>
                        : <Empty description="目录为空" /> }
                    </div>
                </div>
                <aside>
                    <Attachment Module={'articleContent'} Params={param} ></Attachment>
                </aside>
            </div>
        </Fragment>
    )
}