import Navbar from "./components/debris/head/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom"
import {getSiteMenus, portalConfig, sponsorTop} from "./api/welcomePage"
import { useEffect,useState } from "react";
import Home from "./components/home/Home";
import Page404 from "./components/404/Page404"
import Page501 from "./components/501/Page501"
import Article from "./components/article/Article"
import ArticleContent from "./components/article/ArticleContent"
import Catalogue from "./components/catalogue/Catalogue"
import Notice from "./components/notice/Notice"
import Portfolio from "./components/portfolio/Portfolio"
import PortfolioContent from "./components/portfolio/PortfolioContent"
import NoticeContent from "./components/notice/NoticeContent"
import Blog from "./components/blog/Blog"
import About from "./components/about/About"
import ResumePage from "./components/resume/ResumePage"

const App = () => {
    const [onload, setOnload] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [baseConfig, setBaseConfig] = useState({});
    const [baseSponsor, setBaseSponsor] = useState([]);
    const [picSponsor, setPicSponsor] = useState([]);
    const [instation, setInstation] = useState([]);
    useEffect(() => {
        if(onload) {
            getSiteMenus().then(response => {
                setMenuItems([...response.result])
            })
            portalConfig().then(response => {
                setBaseConfig({...response.result})
            })
            sponsorTop('default',7).then(response => {
                setBaseSponsor([...response.result])
            })
            sponsorTop('picSponsor',9).then(response => {
                setPicSponsor([...response.result])
            })
            sponsorTop('instation',9).then(response => {
                setInstation([...response.result])
            })
            setOnload(false)
        }
    },[onload])
    return (
        <>
            <header>
                <div className="header_wrap">
                    <h1 className="logo"><a href="/">名字在嘴边</a></h1>
                    <Navbar menuItems={menuItems} />
                </div>
            </header>
            <div className="page_wrap">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home Signature={baseConfig.portalSignature} MenuItems={menuItems}/>} ></Route>
                        <Route path="home" element={<Home Signature={baseConfig.portalSignature} MenuItems={menuItems}/>} ></Route>
                        <Route path="catalogue" element={<Catalogue />} ></Route>
                        <Route path="catalogue/:id" element={<Catalogue />} ></Route>
                        <Route path="article/:id" element={<Article />}  />
                        <Route path="articleDetails/:articleId/:id" element={<ArticleContent />}  />
                        <Route path="notice/:signboard" element={<Notice />} ></Route>
                        <Route path="noticeDetails/:id" element={<NoticeContent />}  />
                        <Route path="blog" element={<Blog />} ></Route>
                        <Route path="portfolio" element={<Portfolio/>}></Route>
                        <Route path="portfolioDetails/:id" element={<PortfolioContent />}  />
                        <Route path="about" element={<About />} ></Route>
                        <Route path="resume" element={<ResumePage />} ></Route>
                        <Route path="page501" element={<Page501 />} ></Route>
                        <Route path="*" element={<Page404 />}/>
                    </Routes>
                </BrowserRouter>
            </div>
            <footer>
                <div className="footer-wrap">
                    <span className="totop"><a href="#" title="Back to top">top</a></span>
                    <div className="one-fourth first">
                        <h3>详细地址</h3>
                        <p>手机号: +{baseConfig.portalPhone}<br></br> E-mail: <a href="#">{baseConfig.portalEmail}</a></p>
                        <p>{baseConfig.portalAddress}</p>
                    </div>
                    <div className="one-fourth">
                        <h3>友情链接</h3>
                        <ul className="links">
                            {baseSponsor.filter(image => image.sponsorName).map((image) => (
                                <li><a href={image.sponsorRedirect?image.sponsorRedirect:'#'}>{image.sponsorName}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className="one-fourth second">
                        <h3>站内推荐</h3>
                        <ul className="links">
                            {instation.filter(image => image.sponsorName).map((image) => (
                                <li><a href={image.sponsorRedirect?image.sponsorRedirect:'#'}>{image.sponsorName}</a></li>
                            ))}
                        </ul>
                    </div>
                    <div className="one-fourth">
                        <h3>对您可能有帮助的</h3>
                        <ul className="flickr ">
                            {picSponsor.filter(sponsor => sponsor.sponsorLogo).map((sponsor,index) => (
                                <li className= {index%3===0 && ('first')} key={index}>
                                    <a href={sponsor.sponsorRedirect? sponsor.sponsorRedirect: '#'}><img src={sponsor.sponsorLogo} alt={sponsor.sponsorName}/></a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="copyright">
                    <div className="copy-wrap">
                        <p><a href="https://beian.miit.gov.cn/" target="_blank">{baseConfig.portalFooter}</a></p>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default App;
