import './TabsShow.css'
import { Empty,Tabs, Skeleton, Flex, Typography, Collapse } from 'react-vant'
import { Fragment,useEffect,useState } from "react";
import {assemblyParam} from "../../../utils/universal";

export default function TabsShow({Themes,CheckIndex,LoadContentFunction}){

    const [checkIndex, setCheckIndex] = useState(0);
    const [baseContext, setBaseContext] = useState([{pic:'#',title:'标题',content:'内容',url:'#',attribute:{}}]);
    const [themes, setThemes] = useState([]);

    const changeActive =(tabIndex) => {
        if (LoadContentFunction) {
            LoadContentFunction(Themes[tabIndex].value).then(response => {   setBaseContext( response ) })
        }
        console.log(baseContext)
    }

    useEffect(() => {
        setCheckIndex(CheckIndex)
        if (LoadContentFunction) {
            LoadContentFunction(Themes[CheckIndex].value).then(response => {   setBaseContext( response ) })
        }
        setThemes(Themes.map( sing => { sing.type = (sing.type || 'catalogue'); return sing } ))
    },[CheckIndex])
    return (
        <div className="whole">
            <Tabs defaultActive={checkIndex} align='start' onChange={changeActive} >
                {themes.map((notice,index) => (
                    <Tabs.TabPane key={notice.value} title={notice.label}>
                        <div className="tabsContainer">
                            <div className="tabsPage">
                                {(baseContext && baseContext.length > 0)? (
                                    <Fragment>
                                        {/*文章*/}
                                        { notice.type === 'article' && (
                                            baseContext.map((item,index) => (
                                                <Skeleton avatar loading={!item.content} key={index}>
                                                    <Flex className="subject-content">
                                                        { (item.pic && item.pic !=='#') &&  <img alt={item.title} src={item.pic} /> }
                                                        <div className="field-content">
                                                            <Typography.Title>{item.title}</Typography.Title>
                                                            <Typography.Text type="secondary"  ellipsis={{rows: 2, collapseText: '收起', expandText: '展开'}} >
                                                                {item.content}
                                                            </Typography.Text>
                                                            { (item.url && item.url !== '#') && <Typography.Link href= { assemblyParam(item.url,{},item.attribute)}> >>> </Typography.Link> }
                                                        </div>
                                                    </Flex>
                                                </Skeleton>
                                            ))
                                        )}
                                        {/*折叠面板*/}
                                        { notice.type === 'notice' && (
                                            <Collapse initExpanded="1" accordion>
                                                {
                                                    baseContext.map((item,index) => (
                                                        <Collapse.Item title={item.title} name={index} key={index}>
                                                            <Typography.Text type="secondary"  ellipsis={{rows: 2, collapseText: '收起', expandText: '展开'}} >
                                                                {item.content}
                                                            </Typography.Text>
                                                            { (item.url && item.url !== '#') && <Typography.Link href= { assemblyParam(item.url,{},item.attribute)}> >>> </Typography.Link> }
                                                        </Collapse.Item>
                                                    ))
                                                }
                                            </Collapse>
                                        )}
                                        {/*目录*/}
                                        { notice.type === 'catalogue' && (
                                            <ul className="cat">
                                                {
                                                    baseContext.map((item,index) => (
                                                        <li key={index}><a style={{fontSize:'10px'}} href={ assemblyParam(item.url,{id:item.id},item.attribute)}>&rArr;&#12288;&#12288;{item.title}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        )}
                                        {/*标签*/}
                                        { notice.type === 'tags' && (
                                            <div className="tabsTag">
                                                <ul className="cat tag">
                                                    {baseContext.map((item,index) => (
                                                        <li key={index}><a href={item.url}>{item.title}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </Fragment>
                                ) : <Empty/>}
                            </div>
                        </div>
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </div>
    )
}