import React,{Fragment,useEffect,useState } from "react";
import {assemblyParam} from '../../../utils/universal'
import './Timewarp.css'
export default function Timewarp({TimeLine}){
    const [date, setDate] = useState({year: '',month: '',day: ''});
    const [paragraph, setParagraph] = useState({id:'',timelineTheme:'时间线主题',timelineDocumentId:'文档iD',timelineBrief:'内容'
        ,timelineDocumentType: '文档类型',timelinePreview: '预览地址',timelineAttribute: '{}', timelinePublisher:'作者',createTime:'发布时间'});
    useEffect(() => {
        setParagraph(TimeLine)
        const createTime = new Date(TimeLine.createTime);
        setDate({year: createTime.getFullYear(),month: createTime.getMonth()+1,day: createTime.getDate()})
    }, [TimeLine])

    const typeTransform = (type) => {
        let typeName
        switch(paragraph.timelineDocumentType){
            // 0:文章;1:相册;2:公告;3:公告
            case '0':
                typeName = '文章'
                break;
            case '1':
                typeName = '相册'
                break;
            case '2':
                typeName = '公告'
                break;
            case '3':
                typeName = '公告'
                break;
            default:

        }
        return typeName
    }

    const addressTransform = (type,documentId,attribute) => {
        let address
        switch(paragraph.timelineDocumentType){
            // 0:文章;1:相册;2:公告;3:公告
            case '0':
                address = '/articleDetails/{categoryId}/{id}'
                break;
            case '1':
                address = '/portfolioDetails/{id}'
                break;
            case '2':
                address = '/noticeDetails/{id}'
                break;
            case '3':
                address = '公告'
                break;
            default:

        }
        return assemblyParam(address,{id:documentId},JSON.parse(attribute || '{}'))
    }

    return (
        <Fragment>
            <article>
                <div className="categ">
                    <div className="meta"><strong>{date.day}</strong>
                        <small>{date.month}月 <br></br>{date.year}</small>
                    </div>
                    <ul>
                        <li>类型: <a href="#">{typeTransform(paragraph.timelineDocumentType)}</a></li>
                        {/*<li><a href="single.html">3 条评论</a></li>*/}
                        {/*<li>发布人: <a href="single.html">{paragraph.timelinePublisher}</a></li>*/}
                    </ul>
                </div>
                <div className="postblog">
                    {(paragraph.timelinePreview && paragraph.timelinePreview !== '#') && <a href={addressTransform(paragraph.timelineDocumentType,
                        paragraph.timelineDocumentId,paragraph.timelineAttribute)} className="image-blog"><img src={paragraph.timelinePreview} alt=""/></a> }
                    <h3><a href={addressTransform(paragraph.timelineDocumentType,
                        paragraph.timelineDocumentId,paragraph.timelineAttribute)}>
                        <div className="article-paragraph" dangerouslySetInnerHTML={{__html: paragraph.timelineTheme}}></div>
                        </a></h3>
                    <p><div className="article-paragraph" dangerouslySetInnerHTML={{__html: paragraph.timelineBrief}}></div></p>
                    <span><a href={addressTransform(paragraph.timelineDocumentType,
                        paragraph.timelineDocumentId,paragraph.timelineAttribute)}>详情 &rArr;</a></span>
                    {/*<span className="right-bot"><a href="single.html">评论 (3) &rArr;</a></span>*/}
                </div>
            </article>
        </Fragment>
    )
}