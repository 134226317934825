import http,{getSystem} from '../utils/http';
import {assemblyParam} from '../utils/universal'

/**
 * 获取品类列表
 */
function getCategoryTheme(signage,parentId){
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iCategory/plane/'+getSystem()+'/'+signage+'/'+parentId).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 获取品类项
 */
function getCategoryItems(signage,parentId){
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iCategory/tree/'+getSystem()+'/'+signage+'/'+parentId).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 文章列表
 */
function articlePage(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iArticle/portal/page',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 文章内容
 */
function articleContent(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iArticle/base/{id}',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 公告列表
 */
function noticePage(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iPortalNotice/portal/page',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 公告内容
 */
function noticeContent(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iPortalNotice/base/{id}',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 时间线内容
 */
function timeLineContent(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iPortalTimeline/page',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 相册列表
 */
function albumList(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iAlbumSignboard/portal/album',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 相册内容
 */
function albumContent(params){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iAlbumSignboard/portal/content/{id}',params,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 查询团队信息通过系统编码
 */
function teamBySystemCode(){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iTeam/systemCode/{systemCode}',{systemCode:getSystem()} ,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/**
 * 推荐简历
 */
function recommendResume(){
    return new Promise((resolve, reject) => {
        const path = assemblyParam('/synthesis/iResume/recommend/{systemCode}',{systemCode:getSystem()} ,{})
        http("get",path).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

export {
    getCategoryTheme, getCategoryItems, getSystem, articlePage, articleContent, noticePage, noticeContent, timeLineContent, albumList, albumContent,
    teamBySystemCode, recommendResume
}