import MenuItems from "./MenuItems";

export default function Navbar ({menuItems}){
    return (
        <nav class="ddsmoothmenu">
            <ul className="menus">
                {menuItems.map((menu, index) => {
                    return <MenuItems items={menu} key={index} />;
                })}
            </ul>
        </nav>
    );
};