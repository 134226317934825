import http,{getSystem} from '../utils/http';

/**
 * 获取站点菜单
 */
function getSiteMenus(){
    return new Promise((resolve, reject) => {
        http("get",'/auth/menu/tourist/portal').then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/*--------------------------
全局配置
---------------------------*/
function portalConfig() {
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iPortalConfig/system/'+ getSystem()).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/*--------------------------
轮播图
---------------------------*/
function carouselPreview() {
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iPortalCarousel/array/'+getSystem()+'/default').then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/*--------------------------
公告
---------------------------*/
function noticeTop(signage,num) {
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iPortalNotice/array/'+getSystem()+'/'+ signage + '?num=' + num).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/*--------------------------
赞助方
---------------------------*/
function sponsorTop(signage,num) {
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iPortalSponsor/array/'+getSystem()+'/'+ signage + '?num=' + num).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

/*--------------------------
推荐信息
---------------------------*/
function recommendTop(signage,num,keyword) {
    return new Promise((resolve, reject) => {
        http("get",'/synthesis/iPortalRecommend/array/'+getSystem()+'/'+ signage + '?num=' + num + '&keyword=' + keyword).then(res => {
            resolve (res);
        },error => {
            console.log("网络异常~",error);
            reject(error)
        })
    })
}

export {
    getSiteMenus ,portalConfig, carouselPreview, noticeTop, sponsorTop, recommendTop
}
