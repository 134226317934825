import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// styles
import "./app.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);