import React,{Fragment,useEffect,useState } from "react";
import { useParams } from 'react-router-dom'
import { Empty } from 'react-vant';
import Search from "../debris/search/Search";
import Pagination from "../debris/pagination/Pagination"
import {getSystem,albumList} from "../../api/articlePage";
export default function Portfolio(){
    const params = useParams();
    const [param, setParam] = useState({pageSize: 9,pageNo: 1, systemCode: getSystem()});
    const [pages, setPages] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    function clickPage(pageNo) {
        setPageNo(pageNo)
        setParam({ ...param, ...{pageNo: pageNo}})
    }
    useEffect(() => {
        albumList(param).then(response => {
            if (response) {
                const result = response.result
                setPages( result ? result.rows : [])
                setPageTotal(result ? (result.total || 0) : 0)
            }
        })
    }, [param])

    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'博客'} FirstPageUrl={'/blog'} CurrentPage={'相册列表'}></Search>
            <div className="page_container">
                {(pageTotal && pageTotal > 0 ) ?
                    <Fragment>
                        {pages.map((item,index) => (
                            <div className={index%3===0? 'one-third portfolio portfolio3 first': 'one-third portfolio portfolio3'} key={index}>
                                    <span className="mosaic-block2 views2"><a
                                        href={'/portfolioDetails/'+ item.id} rel="prettyPhoto[gallery1]"
                                        title={item.albumSignboard} className="mosaic-overlay"></a>
                                        <img src={item.albumSignboardCover} alt=""/>
                                    </span>
                                <h3>
                                    <a href={'/portfolioDetails/'+ item.id}>{item.albumSignboard}</a><br></br>
                                    {item.summary && (
                                     <span>
                                        <a href={'/portfolioDetails/'+ item.id}>相册图片数量：{item.summary.total} </a>
                                        <a href={'/portfolioDetails/'+ item.id}>最新更新时间： {item.summary.lastUpdateDate}</a>
                                     </span>
                                    )}
                                </h3>
                            </div>
                        ))}
                        <Pagination clickPage={clickPage.bind(this)} Total={pageTotal} PageNo={pageNo} PageSize={param.pageSize} Span={2}></Pagination>
                    </Fragment>
                    : <Empty description="目录为空" /> }
            </div>
        </Fragment>
    )
}