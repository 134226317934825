import { useEffect,useState,Fragment } from "react";
import {assemblyParam} from '../../../utils/universal'
// { id:'',name:'',url:'' ,property: {},list:[{id:'',url:'',name:'',property: {},hashNext:false}]}
export default function Item({Basepath,Items}){
    const [items, setItems] = useState([]);
    const [basepath, setBasepath] = useState('');
    useEffect(() => {
        setBasepath(Basepath)
        const result = []
        for (let i = 0; i < Items.length; i += 3) {
            result.push(Items.slice(i, i + 3))
        }
        setItems(result)
    },[Items])
    return (
        <Fragment>
            { (items && items.length > 0) && items.map((array,index) => (
                <div className="whole">
                    {
                        (array && array.length > 0) && array.map((item,index) => (
                            <div className={index%3===0? 'one-third first': 'one-third'} key={index}>
                                <h4><a href={item.url}>{item.name}</a></h4>
                                {
                                    (item.list && item.list.length > 0) ?
                                    <ul class="list arrow"> {
                                        item.list.map((sub,index) => (
                                            <li className={sub.hashNext && "hash-next"} >
                                                {sub.hashNext?  <a href={assemblyParam(basepath + '/{id}',{id:sub.id},sub.property)}>{sub.name}</a> : ((sub.url && sub.url !== '#')?
                                                    <a href={assemblyParam(sub.url,{id:sub.id},sub.property)}>{sub.name}</a>: <span style={{color:"#ccc"}} >{sub.name}</span>)}
                                            </li>
                                            )
                                        )}
                                    </ul> :
                                    <ul className="list arrow">
                                        <li>{(item.url && item.url !== '#')?<a href={assemblyParam(item.url,{id:item.id},item.property)}>{item.name}</a>:<span style={{color:"#ccc"}} >{item.name}</span>}</li>
                                    </ul>
                                }
                            </div>
                        ))
                     }
                </div>
            ))}
        </Fragment>
    )
}