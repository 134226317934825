import './about.css'
import { Fragment,useEffect,useState } from "react";
import Search from "../debris/search/Search";
import {teamBySystemCode} from "../../api/articlePage";
export default function About(){
    const [param, setParam] = useState({});
    const [team, setTeam] = useState({})
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    useEffect(() => {
        teamBySystemCode().then(response => {
            setTeam(response.result || {})
        })
    }, [param])
    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'关于'} FirstPage={'主页'} FirstPageUrl={'/home'} CurrentPage={'关于我们'}></Search>
            <div id="page_container">
                <div className="one-half about first">
                    <h3>{team.teamName}</h3>
                    {team.teamIntroduce}
                </div>
                { team.teamInformations &&
                    <div className="one-half about">
                        <h3>我们做什么</h3>
                        { team.teamInformations.map((item,index) => (
                            <p className="drop"><span className="dropcap"> {index+1} </span>{item.teamInformation}</p>
                        )) }
                    </div>
                }
                {team.teamContacts &&
                    <div className="whole about ">
                        <br></br>
                        <h3>我的团队</h3>
                        {team.teamContacts.map((item,index) => (
                            <div className={ index / 2 === 0 ? "one-fourth first" : (index / 2 === 1 ? "one-fourth second" : "one-fourth") }>
                                <img src={item.teamContactsAvatar} alt=""/>
                                <h4>{item.teamContacts}<br></br><span>&mdash; {item.teamContactsChannel}</span></h4>
                                <p>{item.teamContactsBrief}</p>
                            </div>
                        ))}
                    </div>
                }
                <div className="whole article">
                    <br></br>
                    <div dangerouslySetInnerHTML={{__html: team.teamContent}}></div>
                </div>
            </div>
        </Fragment>
    );
}