import { Fragment,useEffect,useState } from "react";
import "./Resume.css"
import {recommendResume} from "../../../api/articlePage";
import { Empty } from 'react-vant';
import { EnvelopO,WapHomeO,PhoneO,HomeO,FriendsO,UserO,ServiceO,ManagerO,ClusterO,MedalO,ExpandO,Ascending,GuideO,ChartTrendingO,NotesO,GemO,LikeO } from '@react-vant/icons';
export default function Resume(){
    const [resume, setResume] = useState({});
    const [links, setLinks] = useState([]);

    useEffect(() => {
        recommendResume().then(response => {
            let res = response.result
            if (res) {
                setResume(res)
                let resumeProjects = res.resumeProjects
                if (resumeProjects) {
                    setLinks(resumeProjects.filter((item) => item.projectLink))
                }
            }
        })
    },[])

    let datePretreatment = function (date){
        return (/\d{4}-\d{1,2}-\d{1,2}/g.exec(date))[0]
    }
    
    return (
        <Fragment>
            {resume ?
                <div className="page_container">
                    <div className="whole resume-head">
                        <div className="one-half first base">
                            <h1> {resume.resumeUser} </h1>
                            {resume.resumePhoto && <img src={resume.resumePhoto}></img>}
                            {resume.resumeProfile &&  <p> {resume.resumeProfile} </p> }
                        </div>
                        <div className="one-half info">
                            {resume.resumeEmail && <Fragment><EnvelopO  /> 邮箱 : <a href={resume.resumeEmail}> {resume.resumeEmail} </a><br></br></Fragment> }
                            {resume.resumeHomePage && <Fragment><WapHomeO  /> 主页 : <a href={resume.resumeHomePage}> {resume.resumeHomePage} </a><br></br></Fragment> }
                            {resume.resumePhone && <Fragment><PhoneO  /> 手机号 : {resume.resumePhone} <br></br></Fragment> }
                            {resume.resumeAddress && <Fragment><HomeO  /> 家庭住址 : {resume.resumeAddress} <br></br></Fragment> }
                            {resume.resumeBirthday && <Fragment><FriendsO  /> 出生日期 : {datePretreatment(resume.resumeBirthday)} <br></br></Fragment> }
                            {resume.resumeGenderName && <Fragment><UserO  /> 性别 : {resume.resumeGenderName} <br></br></Fragment> }
                            {resume.resumeStatuName && <Fragment><ServiceO  /> 工作状态 : {resume.resumeStatuName} <br></br></Fragment> }
                            {resume.iresumeNationName && <Fragment><ManagerO  /> 民族 : {resume.iresumeNationName} <br></br></Fragment> }
                            {resume.iresumeIngredientName && <Fragment><ClusterO  /> 家庭成分 : {resume.iresumeIngredientName} <br></br></Fragment> }
                            {resume.resumeAcademicName && <Fragment><MedalO  /> 学历 : {resume.resumeAcademicName} <br></br></Fragment> }
                        </div>
                    </div>
                    <div className="whole">

                        <div className="one-fifth about">
                            {(resume.resumeTechClassifys && resume.resumeTechClassifys.length > 0 ) &&
                            <Fragment>
                                <div className="specialty"><ExpandO  /> 特长</div>
                                <div className="skills">
                                    <div className="skill">
                                        {resume.resumeTechClassifys.map((item,index) => (
                                            <div className="padding">
                                                {item.classifyName &&
                                                <Fragment>
                                                    <div className="theme">
                                                        {item.classifyName}
                                                    </div>
                                                </Fragment>
                                                }
                                                {(item.subClassifys && item.subClassifys.length> 0) &&
                                                <ul>
                                                    { item.subClassifys.map((sub,index) => (<li> {sub.classifyName} </li>))}
                                                </ul>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Fragment>
                            }
                            {(links && links.length>0) &&
                            <Fragment>
                                <br></br>
                                <div className="specialty"><Ascending  />参与项目</div>
                                <br></br>
                                <div className="skills">
                                    {links.map((item,index) => (
                                        <div><a href={item.projectLink}> {item.projectName} <GuideO  /></a></div>
                                    ))}
                                </div>
                            </Fragment>
                            }
                        </div>

                        <div className="three-fourth about">
                            {(resume.resumeWorks && resume.resumeWorks.length > 0) &&
                            <Fragment>
                                <div className="whole specialty"><ChartTrendingO  /> 工作经验</div>
                                {resume.resumeWorks.map((item,index) => (
                                    <div className="whole kernel">
                                        {item.workName ? <Fragment><div className="title one-half first">{item.workName}</div></Fragment>:<div className="one-half first" />}
                                        <div className="timeline one-half">
                                            {datePretreatment(item.workStartTime)} - {item.workEndTime?datePretreatment(item.workEndTime):'至今'}
                                        </div>
                                        <div className="whole items">
                                            {(item.workItems && item.workItems.length>0 &&
                                                <ul>
                                                    {item.workItems.map((sub,index) => (<li> {index+1}:{sub.teamItem}</li>))}
                                                </ul>
                                            )}
                                            {(item.workTechusedNames && item.workTechusedNames.length>0) &&
                                            <Fragment>
                                                <div className="techused">技术选择 : {item.workTechusedNames.join(' , ')}</div>
                                            </Fragment>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </Fragment>
                            }
                            {(resume.resumeProjects && resume.resumeProjects.length > 0) &&
                            <Fragment>
                                <div className="whole specialty"><NotesO  /> 项目介绍</div>
                                {resume.resumeProjects.map((item,index) => (
                                    <Fragment>
                                        <div className="whole kernel">
                                            {item.projectName ? <div className="title one-half first">{item.projectName}</div>:<div className="one-half first" />}
                                            <div className="timeline one-half"> {datePretreatment(item.projectStartTime)} - {item.projectEndTime?datePretreatment(item.projectEndTime):'至今'} </div>
                                            <div className="whole items">
                                                {(item.projectItems && item.projectItems.length > 0 ) && <ul>{item.projectItems.map((sub,index) => (<li>{sub.teamItem}</li>))}</ul>}
                                                {(item.projectTechusedNames && item.projectTechusedNames.length>0) && <div className="techused">技术选择 : {item.projectTechusedNames.join(' , ')}</div>}
                                            </div>
                                        </div>
                                    </Fragment>
                                ))}
                            </Fragment>
                            }
                            {(resume.resumeAcademics && resume.resumeAcademics.length > 0) &&
                            <Fragment>
                                <div className="whole specialty"><GemO  /> 学历与资质</div>
                                {resume.resumeAcademics.map((item,index) => (
                                    <div className="whole kernel">
                                        <div className="title one-half first">{item.academicName}</div>
                                        <div className="timeline one-half"> {datePretreatment(item.academicStartTime)} - {item.academicEndTime?datePretreatment(item.academicEndTime):'至今'} </div>
                                    </div>
                                ))}
                            </Fragment>
                            }
                            { resume.iresumeNterested &&
                            <Fragment>
                                <div className="whole specialty"><LikeO  /> 兴趣与爱好</div>
                                <div className="whole kernel">
                                    <p>{resume.iresumeNterested}</p>
                                </div>
                            </Fragment>
                            }
                        </div>
                    </div>
                </div>
            : <Empty/>}
        </Fragment>
    )
}