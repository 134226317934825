import './Attachment.css'
import { Fragment,useEffect,useState } from "react";
import { Toast, Calendar ,Cell  } from 'react-vant'
import Post from "../post/Post";
import TabsShow from "../tabs/TabsShow";
import {recommendTop} from "../../../api/welcomePage";
import {getCategoryItems} from "../../../api/articlePage";

export default function Attachment({Module,Params}){
    const [recommends, setRecommends] = useState([]);

    useEffect(() => {
        recommendTop('information', 4, '').then(response => {
            setRecommends(response.result || [])
        })
    },[Module,Params])

    const changeActiveComponent =(name) => {
        return new Promise((resolve, reject) => {
            console.log(name)
            if(name === 'tags') {
                resolve([])
            } else {
                getCategoryItems('default', 0).then(response => {
                    resolve(
                        (response.result || []).map(item => {return {id: item.id , title: item.categoryName, url: (item.categoryUrl && item.categoryUrl !== '#')? item.categoryUrl : ( '/catalogue/'+ item.id), author: '',subsets: item.subsets, pic: '', content: '', attribute: JSON.parse(item.categoryProperty || '{}')}})
                    )
                })
            }
        })
    }

    return (
        <Fragment>
            <TabsShow CheckIndex={0} Themes={[{value: 'catalogue',label: '目录',type:'catalogue'},{value: 'tags',label: '感兴趣的',type:'tags'}]}  LoadContentFunction={changeActiveComponent} ></TabsShow>
            <div className="whole">
                <Post Theme={'推荐资源'} Recommends={recommends} ></Post>
            </div>
            <div className="whole">
                <Calendar showConfirm={false} poppable={false} showTitle={false} maxDate={new Date()} value={new Date()} />
            </div>
        </Fragment>
    )
}