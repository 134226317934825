import './Product.css'
import { ProductCard } from 'react-vant';
export default function Product({theme,products}){
    return (
        <div className="row">
            {theme && (<div className="page-title"><h3>{theme}</h3></div>)}
            {
                products.map((product,index) => (
                    <ProductCard className="product-item" {...product} />
                ))
            }
        </div>
    )
}