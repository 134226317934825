import React,{Fragment,useEffect,useState } from "react";
import { useParams } from 'react-router-dom'
import { Empty } from 'react-vant';
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import Brief from "../debris/brief/Brief";
import Pagination from "../debris/pagination/Pagination"
import {getSystem,articlePage} from "../../api/articlePage";
export default function Article(){
    const params = useParams();
    const [param, setParam] = useState({pageSize: 10,pageNo: 1,categoryId: params.id, systemCode: getSystem()});
    const [pages, setPages] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageTotal, setPageTotal] = useState(0);
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    function clickPage(pageNo) {
        setPageNo(pageNo)
        setParam({ ...param, ...{pageNo: pageNo}})
    }
    useEffect(() => {
        articlePage(param).then(response => {
            const result = response.result
            setPages( result ? result.rows : [])
            setPageTotal(result ? (result.total || 0) : 0)
        })
    }, [param])

    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'博客'} FirstPageUrl={'/blog'} CurrentPage={'文章列表'}></Search>
            <div className="page_container">
                <div className="container">
                    {(pageTotal && pageTotal > 0 ) ?
                        <Fragment>
                            {pages.map((item,index) => (
                                <Brief key={index} ArticleContent={{id: item.id,title: item.articleTitle,signature: item.articleAuthor + '(' + item.articleReleaseTime + ')'
                                    ,abstract: item.articleAbstract ,cover: item.articleCover}} Href={'/articleDetails/'+params.id+'/{id}'}></Brief>
                            ))}
                            <Pagination clickPage={clickPage.bind(this)} Total={pageTotal} PageNo={pageNo} PageSize={param.pageSize} Span={2}></Pagination>
                        </Fragment>
                        : <Empty description="目录为空" /> }
                </div>
                <aside>
                    <Attachment Module={'article'} Params={param} ></Attachment>
                </aside>
            </div>
        </Fragment>
    )
}