import React,{Fragment,useEffect,useState } from "react";
import { useParams } from 'react-router-dom'
import Search from "../debris/search/Search";
import Attachment from "../debris/attachment/Attachment";
import Content from "../debris/content/Content";
import {getSystem, articleContent} from "../../api/articlePage";

export default function ArticleContent(){
    const params = useParams();
    const [content, setContent] = useState({});
    const [param, setParam] = useState({id: params.id, systemCode: getSystem()});
    function searchParam(search) {
        setParam({ ...param, ...{keyword: search}})
    }
    useEffect(() => {
        articleContent(param).then(response => {
            const data = response.result || {}
            setContent({id: data.id,title: data.articleTitle,description: data.articleDescription,inside: 'true',multimedia: data.articleCover ,content: data.articleContent,author: data.articleAuthor,releaseTime: data.articleReleaseTime })
        })
    }, [param])
    return (
        <Fragment>
            <Search searchKeyword={searchParam.bind(this)} Home={'文章列表'} FirstPageUrl={'/article/'+params.articleId} CurrentPage={'文章详情'}></Search>
            <div className="page_container">
                <div className="container">
                    <Content Paragraph={ content }></Content>
                </div>
                <aside>
                    <Attachment Module={'articleContent'} Params={param} ></Attachment>
                </aside>
            </div>
        </Fragment>
    )
}